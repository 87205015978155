import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";
import { Link } from "./components/Links";
import { getSignupLink } from "./js/helpers";

interface PricingCardProps extends BasicProps {
  header: string,
  body: JSX.Element | Array<JSX.Element>,
  price: string,
  footer: string | JSX.Element | Array<JSX.Element>,
  to: string,
}
const PricingCard = ({ children, className, header, body, price, footer, to }: PricingCardProps) => {
  const history = useHistory();
  return (
    <div
      className={`flex flex-col gap-2 md:gap-8 border rounded-3xl border-gray-500 p-4 min-w-min text-center cursor-pointer ${className}`}
      onClick={() => to.slice(0, 1) === "/" ? history.push(to) : window.location.href = to} >
      <h1 className="text-3xl sm:text-4xl font-semibold">{header}</h1>
      <div className="text-left">{body}</div>
      <div className="text-2xl">{price}</div>
      <div className="flex">
        <div className="mx-auto border bg-gray-400 bg-opacity-30 py-2 px-3 rounded-lg">{footer}</div>
      </div>
    </div>
  );
};

const Pricing = function () {
  return (
    <div>
      <Helmet>
        <title>Pricing | API Pruner</title>
        <meta name="description" content="Pricing information for the API Pruner service." />
      </Helmet>
      <div className="flex flex-col gap-2 mb-3">
        <p className="text-2xl">Pricing</p>
        <p>API Pruner is free to get started with, and has plans available to meet your needs as they grow.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 max-w-md md:max-w-full mx-auto md:mx-0 gap-6 px-4 pb-4">
        <PricingCard
          to={getSignupLink("/subscribe/personal")}
          header="Personal"
          body={<div>
            <p>🙂 1,000 daily requests</p>
            <p>🙂 Feature requests considered</p>
            <p>❌ No support guarantees</p>
          </div>}
          price="$5/month"
          footer="Click here to Subscribe"
          className="hover:bg-blue-200" />
        <PricingCard
          to={getSignupLink("/subscribe/business")}
          header="Business"
          body={<div>
            <p>😀 10,000 daily requests</p>
            <p>🙂 Feature requests considered</p>
            <p>🙂 72 hour support turnaround</p>
          </div>}
          price="$25/month"
          footer="Click here to Subscribe"
          className="bg-blue-100 hover:bg-blue-200" />
        <PricingCard
          to={getSignupLink("/subscribe/enterprise")}
          header="Enterprise"
          body={<div>
            <p>🤩 100,000 daily requests</p>
            <p>😀 Feature requests prioritised</p>
            <p>😀 24 hour support turnaround</p>
          </div>}
          price="$99/month"
          footer="Click here to Subscribe"
          className="bg-gray-800 hover:bg-gray-900 text-gray-50" />
      </div>
      <div className="flex flex-col gap-2 mb-2">
        <p>If your needs exceed the Enterprise-tier plan, please <Link to="/contact">contact us</Link> to arrange a custom plan.</p>
        <p>If you just want to get started with API Pruner under the free Starter tier limitations, visit the <Link to="/get_started">Get Started</Link> page.</p>
        <p className="text-2xl mt-2">Pricing plan notes: </p>
        <ul className="list-disc list-inside">
          <li>All prices are in Australian Dollars.</li>
          <li>Daily usage is reset at midnight UTC.</li>
          <li>Usage of the API where no API key is provided is considered under the "Starter" tier.</li>
          <li>Starter tier use of the API is not for use in commercial applications and is rate limited to 100 daily requests by using your IP.</li>
          <li className="ml-6">This means that if your IP is shared with others using the service, they will be sharing your allocated pool.</li>
          <li className="ml-6">The only way around this is to upgrade to a paid plan.</li>
          <li>Support turnaround windows specify the maximum amount of time that it will take for support to respond to you on a support request.</li>
        </ul>
      </div>
    </div>
  );
}

export default Pricing;
