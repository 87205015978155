import axios from "axios";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { StandardSpinner } from "./components/Spinners";
import { errorToString } from "./js/helpers";
import { Helmet } from "react-helmet-async";

interface SubscribeProps {
  user: CognitoUser,
}
interface SubscribeParams {
  tier: string,
}
const Subscribe = function ({ user }: SubscribeProps) {
  const [firstRun, setFirstRun] = useState(true);
  const [error, setError] = useState<OptionalString>(null);
  const { tier } = useParams<SubscribeParams>();

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      axios.get(`/subscribe/${tier.toUpperCase()}`)
        .then((res) => typeof (res.data) === "string" ? window.location.href = res.data : setError("No response from auth server"))
        .catch((err) => setError(errorToString(err)));
    }
  }, [firstRun, tier]);

  return (
    <div>
      <Helmet>
        <title>Subscribing | API Pruner</title>
      </Helmet>
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl">Redirecting to Stripe for payment...</h1>
        {error ? <p>{error}</p> : <StandardSpinner />}
      </div>
    </div>
  )
}

export default Subscribe;
