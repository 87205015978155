import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router';

import { ExternalLinkButton, LightButton, LinkButton, StandardButton, WarningButton } from "./components/Buttons";
import { CheckboxInput, MaskElement, TextInput } from './components/Inputs';
import { Link } from './components/Links';
import { SmallSpinner } from './components/Spinners';
import { capitalise, errorToString, getLoginLink, getPasswordResetLink } from './js/helpers';

const SuccessBox = ({ children }: BasicProps) => (
  <div className="rounded bg-green-400 p-4 m-2">{children}</div>
);

const ErrorBox = ({ children }: BasicProps) => (
  <div className="rounded bg-yellow-300 p-4 m-2">{children}</div>
);

interface DetailRowProps extends BasicProps {
  header: string,
}
const DetailRow = ({ children, header }: DetailRowProps) => (
  <tr className="border-t border-b py-2">
    <td className="font-semibold py-2">{header}:</td>
    <td>{children}</td>
  </tr>
);

interface AccountProps {
  user: CognitoUser,
}
const Account = function ({ user }: AccountProps) {
  const history = useHistory();
  const [firstRun, setFirstRun] = useState(true);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [userDetails, setUserDetails] = useState<ArbitraryObject | null>(null);
  const [userDetailError, setUserDetailError] = useState<OptionalString>(null);
  const [accountDetails, setAccountDetails] = useState<ArbitraryObject | null>(null);
  const [accountDetailError, setAccountDetailError] = useState<OptionalString>(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteVerification, setDeletVerification] = useState("");
  const [deletionError, setDeletionError] = useState<OptionalString>(null);
  const [deletionSuccess, setDeletionSuccess] = useState<OptionalString>(null);

  const DELETE_VERIFICATION = `I wish to permanently delete the API Pruner account${userDetails ? ` for ${userDetails.email}` : ""}`;

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (!err && session?.isValid()) {
          user.getUserAttributes((err: Error | undefined, attrs: Array<CognitoUserAttribute> | undefined) => {
            if (!err && attrs !== undefined) {
              let details: ArbitraryObject = {};
              attrs.map((attr) => details[attr.Name] = attr.Value);
              setUserDetails(details);
            } else {
              setUserDetailError("❗ Failed to get user details");
            }
          })
        } else {
          window.location.href = getLoginLink("/account");
        }
      });
      axios.get("/account")
        .then((res: ArbitraryObject) => setAccountDetails(res.data))
        .catch((err) => { setAccountDetailError("❗ " + errorToString(err)) });
    }
  }, [firstRun, history, user]);

  const manageAccount = () => {
    setLoadingPortal(true);
    axios.get("/portal")
      .then((res: ArbitraryObject) => {
        if (res.data) {
          window.location.href = res.data;
        }
      })
      .catch((err) => {
        console.error(errorToString(err));
        setLoadingPortal(false);
      });
  }

  const deleteAccount = () => {
    setDeletionError(null);

    if (!deleting) {
      setDeletionError("Deletion confirmation checkbox not checked");
      return;
    }
    if (deleteVerification !== DELETE_VERIFICATION) {
      setDeletionError("Deletion confirmation text incorrect");
      return;
    }

    axios.delete("/account")
      .then(() => {
        setDeletionSuccess("Account successfully deleted! Returning to home page...");
        setTimeout(() => {
          history.push("/logout");
        }, 3000);
      })
      .catch((err) => setDeletionError(`Error deleting account: ${errorToString(err)}`));
  }

  let rate;
  switch (accountDetails?.tier) {
    case "PERSONAL":
      rate = 1000;
      break;
    case "BUSINESS":
      rate = 10000;
      break;
    case "ENTERPRISE":
      rate = 100000;
      break;
    case "CUSTOM":
      rate = accountDetails.request_limit;
      break;
  }

  return <div>
    <div className="flex flex-col gap-4">
      <Helmet>
        <title>Account | API Pruner</title>
        <meta name="description" content="API Pruner account management page." />
      </Helmet>
      <div className="flex">
        <h1 className="mt-2 text-2xl flex-grow">Account Information</h1>
        <LinkButton to="/logout">Logout</LinkButton>
      </div>
      {userDetailError && <ErrorBox>{userDetailError}</ErrorBox>}
      {accountDetailError && <ErrorBox>{accountDetailError}</ErrorBox>}
      {!userDetails && !userDetailError && !accountDetails && !accountDetailError && <div className="w-min"><SmallSpinner /></div>}
      <table className="ml-4 w-full">
        <tbody>
          {userDetails && (<>
            <DetailRow header="Email">{userDetails?.email}</DetailRow>
          </>)}
          <DetailRow header="Account ID">{user?.getUsername()}</DetailRow>
          {accountDetails && (<>
            <DetailRow header="API Key"><MaskElement element={accountDetails?.api_keys[0].key || ""} /></DetailRow>
            <DetailRow header="Account Created">{new Date(accountDetails?.created).toLocaleString()}</DetailRow>
            <DetailRow header="Tier">{capitalise(accountDetails?.tier)}</DetailRow>
            <DetailRow header="Requests Per Day">{rate || "N/A"}</DetailRow>
            <DetailRow header="Next Payment">
              {(accountDetails?.paid_until && new Date(accountDetails.paid_until).toLocaleDateString()) || <p>Subscription not active - head to the <Link to="/pricing">Pricing page</Link> to subscribe</p>}
            </DetailRow>
          </>)}
        </tbody>
      </table>
      <h1 className="text-2xl">Manage Account</h1>
      <div className="flex gap-2">
        <ExternalLinkButton href={getPasswordResetLink("/account")}>Change Password</ExternalLinkButton>
        {accountDetails?.paid_until && <StandardButton onClick={manageAccount} loading={loadingPortal}>Manage Subscription</StandardButton>}
      </div>
      <h1 className="text-2xl">Advanced Options</h1>
      <div className={showAdvanced ? "hidden" : "block"}>
        <LightButton onClick={() => setShowAdvanced(true)}>Click to show</LightButton>
      </div>
      <div className={`flex flex-col gap-2 ${showAdvanced ? "block" : "hidden"}`}>
        <h2 className="text-xl">Delete Account</h2>
        {deletionError && <ErrorBox>{deletionError}</ErrorBox>}
        {deletionSuccess && <SuccessBox>{deletionSuccess}</SuccessBox>}
        <p>
          This section allows you to delete your account.
          Note that any data associated with your account will be <b>permanently deleted</b>,
          including any contact information.
          Any active subscription you may have will also be cancelled.
        </p>
        <p className="font-semibold text-red-700">
          This process is NON-REVERSIBLE, so ensure that this is definitely what you want to
          do before proceeding.
        </p>
        <CheckboxInput value={deleting} onChange={setDeleting}>Delete Account?</CheckboxInput>
        <TextInput newLine={true} disabled={!deleting} value={deleteVerification} onChange={setDeletVerification}>{`Please enter "${DELETE_VERIFICATION}":`}</TextInput>
        <div>
          <WarningButton
            onClick={deleteAccount}
            disabled={!deleting || deleteVerification !== DELETE_VERIFICATION}>
            Delete Account
          </WarningButton>
        </div>
      </div>
    </div>
  </div >
}

export default Account;
