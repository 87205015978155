import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SmallSpinner } from "./components/Spinners";
import { capitalise, errorToString } from "./js/helpers";
import { Link } from "./components/Links";
import { Helmet } from "react-helmet-async";

const ErrorBox = ({ children }: BasicProps) => (
  <div className="rounded bg-yellow-300 p-4 m-2">{children}</div>
);

interface SuccessParams {
  checkout_id: string,
}
const Success = function () {
  const [firstRun, setFirstRun] = useState(true);
  const [tier, setTier] = useState<OptionalString>(null);
  const [error, setError] = useState<OptionalString>(null);
  const { checkout_id } = useParams<SuccessParams>();

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      axios.get(`/checkout/${checkout_id}`)
        .then((res: ArbitraryObject) => setTier(res.data.tier))
        .catch((err) => setError(errorToString(err)));
    }
  }, [checkout_id, firstRun]);

  return (
    <div>
      <Helmet>
        <title>Thanks | API Pruner</title>
        <meta name="description" content="Thank you for subscribing to API Pruner!" />
      </Helmet>
      <div className="flex flex-col gap-8 text-center my-6 md:my-20">
        {error && <ErrorBox>{error}</ErrorBox>}
        <h1 className="text-4xl">Thank you for subscribing to API Pruner! 🎉</h1>
        {
          tier || error
            ? <h1 className="text-2xl">Your {tier && capitalise(tier) + " tier "}subscription is all set up and ready to go!</h1>
            : <SmallSpinner />
        }
        <h1 className="text-2xl">Head over to your <Link to="/account">Account</Link> page to grab your API key, and check out the <Link to="/getting_started">Getting Started</Link> page if you're not sure how to use it.</h1>
      </div>
    </div>
  )
}

export default Success;
