import { Helmet } from "react-helmet-async";
import { Link } from "./components/Links";

const About = function () {
  return (<div>
    <Helmet>
      <title>About | API Pruner</title>
      <meta name="description" content="About API Pruner, a webservice for managing API requests and stripping out just the relevant data." />
    </Helmet>
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl">About API Pruner</h1>
      <p>
        API Pruner is an API middleman enabling extracting only the relevant sections of JSON API results without having to load the whole API result yourself.
        This saves you bandwidth, memory, and processing power, all of which are at a premium in embedded designs.
      </p>
      <p>
        In the modern connected world, resource-constrained microcontrollers are being asked to get information from other devices all the time, and often we're only in control of a small part of a large system.
        By allowing less to do more, API Pruner can help allow you to achieve the goals of the customer while meeting the budget limits of the project manager.
      </p>
      <p>
        Head over to the <Link to="/get_started">Get Started</Link> page to find out more about how to integrate API Pruner into your application (and get started now for free!),
        or check out the <Link to="/pricing">Pricing</Link> page to learn how affordable API Pruner is.
      </p>
    </div>
  </div>)
}

export default About;
