import { useState } from "react";
import { LightButton } from "./Buttons";

interface BaseInputProps {
  className?: string,
  children: JSX.Element | string,
  type?: React.HTMLInputTypeAttribute,
  onChange?: Function,
  autoComplete?: string,
  disabled?: boolean,
  newLine?: boolean,
}

interface InputProps extends BaseInputProps {
  value?: string | number | readonly string[],
}

export const GenericInput = (props: InputProps) => {
  return (
    <label className={`flex ${props.newLine ? "flex-col gap-1" : ""} align-middle ${props.className}`}>
      <span className="mt-2"><p className="font-semibold">{props.children}</p></span>
      <input
        type={props.type}
        value={props.value}
        onChange={(event) => props.onChange && props.onChange(event.target.value)}
        autoComplete={props.autoComplete}
        className={`flex-grow ml-2 px-2 py-1 rounded border ${props.disabled ? "border-gray-400 text-gray-400 cursor-not-allowed" : "border-gray-800"}`}
        readOnly={props.disabled} />
    </label>
  )
}


interface TextInputProps extends InputProps {
  maxLength?: number
}
export const TextInput = (props: TextInputProps) => {
  return <GenericInput {...props} type="text" />;
}

interface TextAreaProps extends TextInputProps {
  rows?: number,
  cols?: number,
}
export const TextAreaInput = (props: TextAreaProps) => {
  return (
    <label className={`flex align-middle ${props.className}`}>
      <span className="mt-2"><p className="font-semibold">{props.children}</p></span>
      <textarea
        value={props.value}
        onChange={(event) => props.onChange && props.onChange(event.target.value)}
        className={`flex-grow ml-2 rounded ${props.disabled ? "border-gray-400 text-gray-400" : ""}`}
        rows={props.rows || 4}
        cols={props.cols || 50}
        readOnly={props.disabled} />
    </label>
  )
}

export const PasswordInput = (props: InputProps) => {
  return <GenericInput {...props} type="password" />;
}

export const NumericInput = (props: InputProps) => {
  return <GenericInput {...props} type="number" />;
}

interface CheckboxProps extends BaseInputProps {
  value: boolean | undefined,
}

export const CheckboxInput = (props: CheckboxProps) => {
  return (
    <label className={`flex align-middle ${props.className}`}>
      <span className="mt-1"><p className="font-semibold">{props.children}</p></span>
      <input
        type="checkbox"
        checked={props.value}
        onChange={(event) => props.onChange && props.onChange(event.target.checked)}
        style={{ width: 32, height: 32 }}
        className={`ml-2 rounded ${props.disabled ? "border-gray-400 text-gray-400" : ""}`}
        readOnly={props.disabled} />
    </label>
  );
}

interface Option {
  id: number,
  name: string
}

interface SelectInputProps extends BaseInputProps {
  setId: Function,
  options: Array<Option>,
  value: OptionalString,
}

export const SelectInput = (props: SelectInputProps) => {
  return (
    <label className={`flex align-middle ${props.className}`}>
      <span className="mt-2"><p className="font-semibold">{props.children}</p></span>
      <select
        value={props.value !== null ? props.value : ''}
        onChange={(event) => props.setId(parseInt(event.target.value))}
        disabled={props.options.length < 1}
        className={`flex-grow ml-2 rounded`}>
        {props.options && props.options.map((opt, idx) => (
          <option key={idx} value={opt.id}>{opt.name}</option>
        ))}
      </select>
    </label>
  )
}

interface SelectOrCreateProps extends BaseInputProps {
  textClassName?: string,
  name: string,
  create: boolean,
  setCreate: Function,
  selectOptions: Array<Option>,
  selected?: Option,
  setId: Function,
  value?: string | number | readonly string[] | undefined,
  setValue: Function,
  maxLength: number,
}

export const SelectOrCreateInput = (props: SelectOrCreateProps) => {
  const selectOption = "select"
  const createOption = "create"

  return (
    <div className={`flex align-middle w-full ${props.className}`}>
      <span className={`${props.textClassName}`}><p className="font-semibold">{props.children}</p></span>
      <div className="flex-grow grid grid-cols-2 gap-2">
        <div className="ml-2">
          <input
            type="radio"
            name={props.name}
            value={selectOption}
            checked={!props.create}
            onChange={(event) => props.setCreate(event.target.value === createOption)}
            disabled={!props.selectOptions || props.selectOptions.length < 1}
          />
          <label
            onClick={() => { if (props.selectOptions.length > 0) props.setCreate(false) }}
            className={`ml-1 ${props.selectOptions.length < 1 ? "line-through" : ""}`}>
            Select Existing
          </label>
        </div>
        <div className="ml-2">
          <input
            type="radio"
            name={props.name}
            value={createOption}
            checked={props.create}
            onChange={(event) => props.setCreate(event.target.value === createOption)}
          />
          <label onClick={() => props.setCreate(true)} className="ml-1">Create New</label>
        </div>
        <select
          value={props.selected ? props.selected.id : ''}
          onChange={(event) => props.setId(parseInt(event.target.value))}
          disabled={props.create}
          className={`ml-2 rounded`}>
          {props.selectOptions && props.selectOptions.map((opt, idx) => (
            <option key={idx} value={opt.id}>{opt.name}</option>
          ))}
        </select>
        <input
          type="text"
          value={props.value}
          onChange={(event) => props.setValue(event.target.value)}
          disabled={!props.create}
          className={`ml-2 rounded ${props.create ? "" : "border-gray-400 text-gray-400"}`}
          maxLength={props.maxLength} />
      </div>
    </div>
  )
}

interface MaskElementProps {
  element: string,
}
export const MaskElement = ({ element }: MaskElementProps) => {
  const [shown, setShown] = useState(false);

  const mask = (el: string) => el.slice(0, 0).padEnd(el.length, "x");

  return (
    <div className="flex gap-2">
      <LightButton className="py-1" onClick={() => setShown(!shown)}>
        {`Click to ${shown ? "hide" : "show"}`}
      </LightButton>
      <p className="inline my-auto break-all"><code>{shown ? element : mask(element)}</code></p>
    </div >
  );
};
