import { Helmet } from "react-helmet-async";
import { MaskElement } from "./components/Inputs";

const Contact = function () {
  return (
    <div>
      <Helmet>
        <title>Contact | API Pruner</title>
        <meta name="description" content="How to contact the friendly people at API Pruner." />
      </Helmet>
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl">Contact</h1>
        <p>
          If you need support with API Pruner, would like to raise an issue with the service, or have a suggestion for how API Pruner could better suit your needs, please email us at:
        </p>
        <div className="sm:ml-4">
          <MaskElement element="contact@apipruner.com" />
        </div>
        <p>Thanks for using API Pruner!</p>
      </div>
    </div>
  )
}

export default Contact;
