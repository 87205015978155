import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router';

import { FooterLink, NavbarExternalLink, NavbarLink } from './components/Links';
import { StandardSpinner } from './components/Spinners';
import { PrivacyPolicy, TermsOfService, CookiePolicy, AcceptableUsePolicy } from './components/Terms';
import smallTranspranentLogo from './images/logo_sm.png';
import { getLoginLink } from './js/helpers';

import Home from './Home';
import GetStarted from './GetStarted';
import About from './About';
import Pricing from './Pricing';
import Contact from './Contact';
import Login from './Login';
import Account from './Account';
import Logout from './Logout';
import Subscribe from './Subscribe';
import Success from './Success';

axios.defaults.baseURL = process.env.REACT_APP_AUTH_BACKEND || "http://localhost:8001";

interface CardProps {
  bg?: string,
  className?: string,
  children: JSX.Element,
}

const Card = (props: CardProps) => {
  const bg = props.bg || 'bg-gray-50';
  return (
    <div className="mx-2">
      <div className={`rounded px-4 pt-2 pb-4 mx-auto max-w-screen-lg ${bg} my-2 ${props.className}`}>
        {props.children}
      </div>
    </div>
  );
}

interface RequireUserProps extends BasicProps {
  user: CognitoUser | undefined | null,
}
const RequireUser = ({ user, children }: RequireUserProps) => {
  useEffect(() => {
    if (user === null) {
      window.location.href = getLoginLink(window.location.pathname);
    }
  }, [user]);

  return user ? <div>{children}</div> : <StandardSpinner />;
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [firstRun, setFirstRun] = useState(true);
  const [user, setUser] = useState<CognitoUser | undefined | null>(undefined);

  const clearUser = () => {
    setUser(null);
    axios.defaults.headers.common["Authorization"] = "";
    axios.defaults.headers.common["Account"] = "";
  }

  const getUser = useCallback(() => {
    const data = {
      UserPoolId: process.env.REACT_APP_POOL_ID || "",
      ClientId: process.env.REACT_APP_CLIENT_ID || "",
    }
    const userPool = new CognitoUserPool(data);
    let currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser?.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err || !session?.isValid()) {
          clearUser();
        } else {
          axios.defaults.headers.common["Authorization"] = (
            currentUser?.getSignInUserSession()?.getIdToken().getJwtToken() || ""
          );
          axios.defaults.headers.common["Account"] = currentUser?.getUsername() || "";
          setUser(currentUser);
        }
      });
    } else {
      clearUser();
    };
  }, []);

  const userLogged = useCallback((event: any) => {
    if (event.key.indexOf("CognitoIdentityServiceProvider") !== -1) {
      getUser();
    }
  }, [getUser]);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      window.addEventListener("storage", userLogged);
      getUser();
    }
  }, [firstRun, getUser, userLogged]);

  const login_addr = getLoginLink("/account");
  const require_user = <RequireUser user={user} />;
  return (
    <div
      className="pt-0 min-h-screen flex flex-col"
      style={{
        background: "rgb(36,139,240) linear-gradient(127deg, rgb(32,114,214) 0%, rgb(68,163,255) 100%)"
      }}>
      <ScrollToTop />
      <div className="sticky z-10 top-0 left-0 w-full bg-gray-50 text-blue-900">
        <div className="flex max-w-screen-lg mx-auto">
          <NavbarLink className="flex no-underline" nopad={true} to="/">
            <img
              className="mr-2 mt-1"
              src={smallTranspranentLogo}
              alt="API Pruner logo"
              height={45} width="auto"
              style={{ height: 45, width: "auto" }} />
            <p className="text-lg sm:text-xl md:text-2xl font-logo m-0 font-semibold hidden sm:block sm:mt-3 md:mt-2">API Pruner</p>
          </NavbarLink>
          <h1 className="sm:text-lg md:text-xl pl-4 pt-1 mt-2 lg:block hidden">Trim incoming data trees</h1>
          <div className="flex-grow sm:block hidden" />
          <div className="flex px-1 sm:px-3 pt-2">
            <NavbarLink className="flex no-underline" to="/about">About</NavbarLink>
            <NavbarLink className="flex no-underline" to="/get_started">Get Started</NavbarLink>
            <NavbarLink className="flex no-underline" to="/pricing">Pricing</NavbarLink>
            {user
              ? <NavbarLink className="flex no-underline" to="/account">Account</NavbarLink>
              : (
                <NavbarExternalLink
                  className="flex no-underline"
                  to={login_addr}>
                  Login
                </NavbarExternalLink>
              )
            }
          </div>
        </div>
      </div>
      <div className="flex-grow text-gray-800">
        <Switch>
          {/* Public */}
          <Route path="/about"><Card><About /></Card></Route>
          <Route path="/contact"><Card><Contact /></Card></Route>
          <Route path="/get_started"><Card><GetStarted /></Card></Route>
          <Route path="/pricing"><Card><Pricing /></Card></Route>
          {/* User */}
          <Route path="/login/:to+"><Card><Login /></Card></Route>
          <Route path="/subscribe/:tier"><Card>{user ? <Subscribe user={user} /> : require_user}</Card></Route>
          <Route path="/success/:checkout_id"><Card>{user ? <Success /> : require_user}</Card></Route>
          <Route path="/account"><Card>{user ? <Account user={user} /> : require_user}</Card></Route>
          <Route path="/logout"><Card><Logout onLogout={getUser} /></Card></Route>
          {/* Legal */}
          <Route path="/privacy_policy"><Card><PrivacyPolicy /></Card></Route>
          <Route path="/terms_of_service"><Card><TermsOfService /></Card></Route>
          <Route path="/cookie_policy"><Card><CookiePolicy /></Card></Route>
          <Route path="/acceptable_use_policy"><Card><AcceptableUsePolicy /></Card></Route>
          {/* Default */}
          <Route path="/"><Home /></Route>
        </Switch>
      </div>
      <div className="w-full flex bg-gray-200 text-blue-900">
        <div className="flex max-w-screen-lg flex-grow ml-auto mr-auto px-3 py-6">
          <div className="flex flex-col text-sm gap-1">
            <FooterLink to="/contact">Contact</FooterLink>
            <FooterLink to="/privacy_policy">Privacy Policy</FooterLink>
            <FooterLink to="/terms_of_service">Terms of Service</FooterLink>
            <FooterLink to="/acceptable_use_policy">Acceptable Use Policy</FooterLink>
          </div>
          <p className="flex-grow text-right text-2xl">API Pruner © Corella Creations 2021</p>
        </div>
      </div>
    </div>
  );
}

export default App;
