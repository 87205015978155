interface Data { detail: string }
interface Response { data: Data }
interface ErrorResponse { response: Response }

export const errorToString = function (err: ErrorResponse) {
  let detail = err?.response?.data?.detail;
  if (typeof (detail) == "object") {
    detail = JSON.stringify(detail);
  }
  return detail;
};

export const getObjById = function (list: Array<any>, id: number | null) {
  if (id === null) {
    return null;
  }

  const filteredList = list.filter((el) => el.id === id);
  if (filteredList.length > 0) return filteredList[0];
  return null;
};

export const validateEmail = function (email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export const validatePassword = function (password: string) {
  if (password.length < 8) return "Password must be at least 8 characters";
  return null;
};

export const valuePop = function (list: Array<any>, value: any) {
  return list.filter((e) => (e !== value));
};

export const getDeepProperty = (object: ArbitraryObject, properties: Array<string>) => {
  let retObj = object;
  properties.map((prop) => retObj = retObj[prop]);
  return retObj;
}

export const sortByElement = function <Type>(list: Array<Type>, el: string): Array<Type> {
  // Escape immediately if not able to sort
  if (list.length < 2) return list;

  let sortFn: (a: Type, b: Type) => number;
  const properties = el.split(".");
  if (properties.length < 2) {
    sortFn = (a: ArbitraryObject, b: ArbitraryObject) => {
      if (a[el] < b[el]) return -1;
      if (b[el] > a[el]) return 1;
      return 0;
    }
  } else {
    sortFn = (a: ArbitraryObject, b: ArbitraryObject) => {
      const aEl = getDeepProperty(a, properties);
      const bEl = getDeepProperty(b, properties);
      if (aEl < bEl) return -1;
      if (bEl > aEl) return 1;
      return 0;
    }
  }
  return list.slice().sort(sortFn);
};

export const getBackendAddr = () => process.env.REACT_APP_BACKEND || "https://api.APIpruner.com";
export const getAuthApiAddr = () => process.env.REACT_APP_AUTH_API || "https://authAPI.APIpruner.com";

export const getAuthLink = (to: string, method: string) => (
  `${process.env.REACT_APP_AUTH_ADDR}/${method}?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${window.location.origin}/login${to}`
)
export const getPasswordResetLink = (to: string) => getAuthLink(to, "forgotPassword");
export const getLoginLink = (to: string) => getAuthLink(to, "login");
export const getSignupLink = (to: string) => getAuthLink(to, "signup");

export const capitalise = (s: string) => s && s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase();
