import { Helmet } from "react-helmet-async";

import { HeroLinkButton } from './components/Buttons';

import HeroImage from './images/path-g2c984beb9_1920.jpg';
import IoTImage from './images/network-782707.png';
import WoodcutterImage from './images/tree-100589.jpg';


interface StyleProp { style?: object, [x: string]: any }

const Section = (props: StyleProp) => {
  return <div {...props} style={{ ...props.style, height: "70vh" }} />
}

const Divider = () => <div className="h-1 bg-white" />;

const Home = function () {
  return (
    <div>
      <Helmet>
        <title>API Pruner</title>
        <meta name="description" content="A webservice for managing API requests and stripping out just the relevant data." />
      </Helmet>
      <Section
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}>
        <div className="text-center flex flex-col justify-center h-full w-full backdrop-filter backdrop-blur-sm text-gray-800 px-4 md:px-6 lg:px-8">
          <h1 className="lg:text-6xl md:text-5xl sm:text-4xl text-3xl mb-6">Sometimes there's more data in the tree than you need.</h1>
          <h1 className="lg:text-6xl md:text-5xl sm:text-4xl text-3xl mb-6 font-bold">Maybe it's time to ask someone to prune it back.</h1>
        </div>
      </Section>
      <Divider />
      <Section
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${IoTImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}>
        <div className="text-center flex flex-col justify-evenly h-full w-full backdrop-filter backdrop-blur-sm text-gray-800 px-4 md:px-6 lg:px-8">
          <div><p className="mx-auto max-w-7xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">A more connected world means embedded systems have increasingly large needs, but small memory allowances.</p></div>
          <div><p className="mx-auto max-w-7xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">API designers think they're being kind by using a "more is more" approach when deciding what goes in their APIs, but the poor embedded devices are having their networking allowances and memory footprint destroyed.</p></div>
        </div>
      </Section>
      <Divider />
      <Section
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${WoodcutterImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}>
        <div className="text-center flex flex-col justify-evenly h-full w-full backdrop-filter backdrop-blur-sm text-gray-800">
          <p className="lg:text-5xl md:text-4xl text-3xl"><strong>API Pruner</strong> helps solve this issue.</p>
          <div>
            <p className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl max-w-7xl mx-auto">Send your API request via API Pruner, and tell it what part of the data you want. We'll do the request for you, cut out the section you want, and send you <span className="inline underline">only what you need</span></p>
          </div>
          <div>
            <HeroLinkButton to="/get_started"><><span className="font-semibold inline">Get started</span> (for free!)</></HeroLinkButton>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default Home;
