import { CognitoAuth } from "amazon-cognito-auth-js";

export const getAuth = (): CognitoAuth => {
  const authData = {
    UserPoolId: process.env.REACT_APP_POOL_ID || "",
    ClientId: process.env.REACT_APP_CLIENT_ID || "",
    RedirectUriSignIn: `${window.location.origin}/login`,
    RedirectUriSignOut: `${window.location.origin}/`,
    AppWebDomain: window.location.origin,
    TokenScopesArray: [
      "email",
      "openid",
      "aws.cognito.signin.user.admin",
      window.location.origin + "/account.read",
    ],
  };
  return new CognitoAuth(authData);
}
