import { Helmet } from "react-helmet-async";
import { ExternalLinkButton } from "./components/Buttons";
import { PlainLink } from "./components/Links";
import { getBackendAddr } from "./js/helpers";

const BACKEND_ADDR = getBackendAddr();

const CodeBlock = ({ children }: BasicProps) => {
  return (
    <div className="w-min">
      <div className="border border-gray-400 bg-gray-200 m-3 p-3">
        <code>{children}</code>
      </div>
    </div>
  )
};

const EndpointBlock = ({ children }: BasicProps) => {
  return <CodeBlock><>{BACKEND_ADDR}/{children}</></CodeBlock>;
};

const Parameter = ({ children }: BasicProps) => (
  <p className="font-bold my-2 ml-4">▸<code className="ml-3">{children}</code></p>
);

const GetStarted = function () {

  return (
    <div>
      <Helmet>
        <title>Get Started | API Pruner</title>
        <meta name="description" content="How to get started with API Pruner to manage your API requests and strip out just the relevant data." />
      </Helmet>
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl">Get Started</h1>
        <p>API Pruner is itself just an API. Call the API Pruner API, tell it how to call the API you really care about, and reap your harvest. Below are the different API endpoints available, and how to use them.</p>
        <h1 className="text-2xl">Endpoints</h1>
        <EndpointBlock>prune/json</EndpointBlock>
        <p>This is the endpoint to use for pruning JSON encoded APIs. It needs to be given a few querystring parameters to function:</p>
        <Parameter>url</Parameter>
        <p>This is the url of the API you wish to actually get information from. It needs to be publicly accessible without any kind of authentication, and will be retrieved via a GET request.</p>
        <p>If you're just looking to play around, try using the dummy data endpoint below.</p>
        <Parameter>keys</Parameter>
        <p>These are the keys used to traverse deeper into the JSON tree to get to the data you want. Each "keys" parameter provided retrieves a subset of the tree corresponding to the key provided and adds it to the response under an element with the value of the key, all under a top level "data" key.</p>
        <p>Each key should be a period (<code>.</code>) separated list of element names to navigate into the tree. If you need to navigate into an array, you can provide a number as a key. <code>-1</code> can be used to get the last element of the array.</p>
        <Parameter>headers</Parameter>
        <p>Headers can be specified to be used in the request to the target API url.</p>
        <p>
          Each header should be specified in a separate "headers" parameter, where each parameter is of the format <code>KEY:VALUE</code>.
          The <code>VALUE</code> may be empty, but the <code>KEY</code> must not be.
        </p>
        <p>This can be used to pass things such as API keys through to the target API, but API Pruner API keys must be passed in through the relevant header in the initial request (see the "API Keys" section below).</p>
        <EndpointBlock>prune/xml</EndpointBlock>
        <p>This is the endpoint to use for pruning XML encoded APIs. It needs to be given a few querystring parameters to function:</p>
        <Parameter>url</Parameter>
        <p>This is the url of the API you wish to actually get information from. It needs to be publicly accessible without any kind of authentication, and will be retrieved via a GET request.</p>
        <p>If you're just looking to play around, try using the dummy data endpoint below.</p>
        <Parameter>keys</Parameter>
        <p>These are the keys used to traverse deeper into the XML tree retrieve the text value of the element you want. Each "keys" parameter provided retrieves a subset of the tree corresponding to the key provided and adds it to the response under an element with the value of the key, all under a top level "data" key.</p>
        <p>Each key must be a semicolon (<code>;</code>) separated set of element names and / or attributes to traverse the XML response from the API (e.g. <code>game genre="strategy";...</code>). Multiple keys can be provided to add more data into the reponse.</p>
        <Parameter>headers</Parameter>
        <p>Headers can be specified to be used in the request to the target API url.</p>
        <p>
          Each header should be specified in a separate "headers" parameter, where each parameter is of the format <code>KEY:VALUE</code>.
          The <code>VALUE</code> may be empty, but the <code>KEY</code> must not be.
        </p>
        <p>This can be used to pass things such as API keys through to the target API, but API Pruner API keys must be passed in through the relevant header in the initial request (see the "API Keys" section below).</p>
        <EndpointBlock>dummy/json</EndpointBlock>
        <p>This returns a fixed set of dummy JSON data that you can use to test out pruning API responses. Pass this is as the url parameter of a <code>prune</code> request along with some relevant keys (such as <code>animals</code>, <code>ferrets</code>, <code>0</code>) and check out the response!</p>
        <EndpointBlock>test</EndpointBlock>
        <p>This returns a very simple fixed response, and can be used for verifying connectivity to the API.</p>
        <h1 className="text-2xl">API Keys</h1>
        <p>API Pruner can be used for free right away without the need for any API Keys or registration. If you wish to use API Pruner beyond the Starter-tier limitations (see the <PlainLink to="/pricing">Pricing page</PlainLink> for more information) then you will need to provide your API Key with each request.</p>
        <p>API Keys should be provided through the <code>X-API-Key</code> header, where the value of the header is just the API Key available to you on your <PlainLink to="/account">Account page</PlainLink>.</p>
        <p>Beyond adding the header, there's no other changes required to your workflow when migrating to to a paid subscription.</p>
        <div className="text-center">
          <ExternalLinkButton href={`${BACKEND_ADDR}/docs`} className="text-xl m-4 px-6"><><span className="inline font-semibold">Try it out!</span> It's free!</></ExternalLinkButton>
        </div>
      </div>
    </div>
  )
}

export default GetStarted;
