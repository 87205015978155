import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { StandardSpinner } from "./components/Spinners";
import { getAuth } from "./js/auth";

interface LoginParams {
  to: string,
}
const Login = function () {
  const history = useHistory();
  const [firstRun, setFirstRun] = useState(true);
  const [error, setError] = useState<OptionalString>(null);
  const { to } = useParams<LoginParams>();

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      const auth = getAuth();
      auth.userhandler = {
        onSuccess: (result) => { history.push(to.slice(0, 1) === "/" ? to : "/" + to) },
        onFailure: (err) => setError(err),
      };
      auth.parseCognitoWebResponse(window.location.href);
    }
  }, [firstRun, history, to]);

  return <div>
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl">Logging in...</h1>
      {error ? <p>{error}</p> : <StandardSpinner />}
    </div>
  </div>
}

export default Login;
