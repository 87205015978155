import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { StandardSpinner } from "./components/Spinners";
import { getAuth } from "./js/auth";

interface LogoutProps {
  onLogout: Function,
}
const Logout = function ({ onLogout }: LogoutProps) {
  const history = useHistory();
  const [firstRun, setFirstRun] = useState(true);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      const auth = getAuth();
      // @ts-ignore
      auth.signInUserSession = null;
      auth.clearCachedTokensScopes();
      onLogout();
      history.push("/");
    }
  }, [firstRun, history, onLogout]);

  return <div>
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl">Logging out...</h1>
      <StandardSpinner />
    </div>
  </div>
}

export default Logout;
