import { Link } from 'react-router-dom';
import { SmallSpinner } from './Spinners';

interface BaseButtonProps {
  className?: string,
  children?: string | JSX.Element,
  disabled?: boolean,
}

interface ButtonProps extends BaseButtonProps {
  onClick: Function,
  type?: "button" | "submit" | "reset",
  loading?: boolean,
}

interface LinkButtonProps extends BaseButtonProps {
  to: string,
}

function GenericButton(props: ButtonProps) {
  const onClick = props.onClick || (() => { });

  return (
    <button
      className={`relative border p-2 rounded ${props.disabled ? "cursor-default" : ""} ${props.className}`}
      onClick={() => onClick()}
      type={props.type || "button"}
      disabled={props.disabled || props.loading}>
      {props.loading && <SmallSpinner className="absolute inset-0" />}
      <div className={props.loading ? "invisible" : "visible"}>{props.children}</div>
    </button>
  );
}

export const StandardButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`bg-blue-100 hover:bg-blue-200 border-blue-400 ${props.className}`}>
      {props.children}
    </GenericButton>
  );
}

export const LightButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`bg-gray-200 hover:bg-gray-300 border-gray-400 ${props.className}`}>
      {props.children}
    </GenericButton>
  );
}

export const WarningButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`${props.disabled ? "text-gray-400 cursor-not-allowed" : "text-white hover:bg-red-700 border-red-800"} bg-red-600 ${props.className}`}>
      {props.children}
    </GenericButton>
  );
}

export const LinkButton = (props: LinkButtonProps) => {
  return (
    <Link
      to={props.to}
      className={`border py-2 px-4 rounded bg-blue-100 hover:bg-blue-200 border-blue-400 no-underline inline-block ${props.className}`}>
      {props.children}
    </Link>
  )
}

export const HeroLinkButton = (props: LinkButtonProps) => {
  return (
    <Link
      to={props.to}
      className={`filter drop-shadow-lg border-4 rounded-xl bg-gray-800 hover:bg-gray-900 border-white text-blue-200 no-underline inline-block lg:text-5xl md:text-4xl text-3xl md:px-12 px-6 md:pt-6 pt-3 md:pb-7 pb-4 ${props.className}`}>
      {props.children}
    </Link>
  );
}

interface ExternalLinkButtonProps extends BasicProps {
  href: string,
}
export const ExternalLinkButton = (props: ExternalLinkButtonProps) => {
  return (
    <a
      href={props.href}
      className={`border p-2 rounded bg-blue-100 hover:bg-blue-200 border-blue-400 no-underline inline-block ${props.className}`}>
      {props.children}
    </a>
  )
}

export const SmallLinkButton = (props: LinkButtonProps) => {
  return (
    <Link
      to={props.to}
      className={`border p-1 rounded bg-blue-200 hover:bg-blue-300 border-blue-400 text-black no-underline inline-block ${props.className}`}>
      {props.children}
    </Link>
  )
}

export const DeleteButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`border px-1 py-0 m-1 rounded bg-red-600 hover:bg-red-700 border-red-800 text-white no-underline inline-block ${props.className}`}>
      {props.children || "⨉"}
    </GenericButton>
  )
}
